import {
  Box,
  Button,
  Group,
  ScrollArea,
  Skeleton,
  Text,
  Title,
  UnstyledButton
} from '@mantine/core';
import { useIsomorphicEffect } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import cx from 'classix';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import NoProjectsByType from '@/core/components/atoms/no-projects-by-type/no-projects-by-type';
import RunCard from '@/core/components/molecules/run-card/run-card';
import { Paths } from '@/core/constants/routes.constants';
import { useLatestRuns } from '@/core/hooks/query-hooks/use-latest-runs/use-latest-runs';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';
import { getServerSideProps } from '@/core/utils/get-feature-flags-ssr/get-feature-flags-ssr';
import {
  clearPath,
  getPath
} from '@/core/utils/handle-login-redirect/handle-login-redirect';
import { showNotification } from '@/core/utils/show-notification/show-notification';

// This is to get feature flags into the component
export { getServerSideProps };

const getType = (index: number) => {
  switch (index) {
    case 0:
      return PROJECT_TYPE.TRAINING_INFERENCE;
    case 1:
      return PROJECT_TYPE.PROMPT_EVALUATION;
    default:
      return PROJECT_TYPE.OBSERVE;
  }
};

const Home = () => {
  const router = useRouter();
  const { prompt, observe } = useFeatureFlags();
  const { data: user } = useSession();

  const promptRuns = useLatestRuns({
    type: PROJECT_TYPE.PROMPT_EVALUATION,
    userId: user?.userId
  });

  const observeRuns = useLatestRuns({
    type: PROJECT_TYPE.OBSERVE,
    userId: user?.userId
  });
  const trainingInfRuns = useLatestRuns({
    type: PROJECT_TYPE.TRAINING_INFERENCE,
    userId: user?.userId
  });

  // Computed
  const postSignInUrl = getPath();

  useIsomorphicEffect(() => {
    if (isMobile) {
      showNotification({
        title: 'Log onto Galileo on desktop for a better experience',
        autoClose: false
      });
    }
  }, []);

  useEffect(() => {
    if (postSignInUrl) {
      router.push(postSignInUrl);
      clearPath();
      return;
    }
  }, []);

  return (
    <Box bg='gray.0' h='100%' m={0} px={60} w='calc(100vw - 64px)'>
      <Group justify='space-between' mb={30} mt={80}>
        <Box>
          <Title c='gray.8' fw={700} order={2}>
            Welcome to Galileo!
          </Title>
          <Text c='gray.8' fw={400} size='sm'>
            Your recent runs
          </Text>
        </Box>
        <Group justify='flex-end'>
          <UnstyledButton
            component='a'
            href={Paths.EXTERNAL_GALILEO_DOCS}
            target='_blank'
            variant='light'
          >
            <Text c='brand.6' fw={600} size='sm'>
              Open documentation
            </Text>
          </UnstyledButton>
          <Button
            component='a'
            href={Paths.CREATE_NEW_PROJECT}
            leftSection={<IconPlus size={14} />}
          >
            New project
          </Button>
        </Group>
      </Group>
      {[trainingInfRuns, promptRuns, observeRuns].map((runsData, index) => {
        if (!prompt.isEnabled && index === 1) return null;
        if (!observe.isEnabled && index === 2) return null;

        const isLoading = runsData.isLoading;

        const runs = runsData.data?.runs;
        const projectType = getType(index);
        const height = projectType === PROJECT_TYPE.OBSERVE ? 70 : 85;
        return (
          <Box key={index} mb={24} w='100%'>
            <ProjectTypeLabel projectType={projectType} />
            {isLoading ? (
              <Skeleton h={50} radius='md' w='100%' />
            ) : !runs?.length ? (
              <NoProjectsByType type={projectType} />
            ) : (
              <ScrollContainer height={height}>
                {runs?.map((run) => <RunCard key={run.id} run={run} />)}
              </ScrollContainer>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
const ScrollContainer = ({
  children,
  height
}: {
  children: React.ReactNode;
  height: number;
}) => {
  const viewport = useRef<HTMLDivElement>(null);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

  const showFade =
    (viewport.current?.scrollWidth || 0) -
      (viewport.current?.clientWidth || 0) >
    scrollPosition.x;

  return (
    <Box className={cx(showFade && 'right-fade')}>
      <ScrollArea
        h={height}
        viewportRef={viewport}
        w='100%'
        onScrollPositionChange={onScrollPositionChange}
      >
        <Group
          h={height}
          style={{
            flexWrap: 'nowrap'
          }}
        >
          {children}
        </Group>
      </ScrollArea>
    </Box>
  );
};

function ProjectTypeLabel({ projectType }: { projectType: ProjectType }) {
  const { label } = useProjectType(projectType);
  return (
    <Text c='#706C89' fw={700} mb={12} size='xs' tt='uppercase'>
      {label}
    </Text>
  );
}

Home.auth = true;

export default Home;
