import { Box, Center, Group, Text, UnstyledButton } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { useRouter } from 'next/router';

import { Paths } from '@/core/constants/routes.constants';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';

const NoProjectsByType = ({ type }: { type: ProjectType }) => {
  const router = useRouter();
  const { setIsProjectMenuOpen } = useGlobalStore((state) => state.actions);
  const { label, Icon, shortLabel } = useProjectType(type);

  return (
    <Box data-testid='no-projects'>
      <Center mb={12}>
        <Center
          h={40}
          style={{
            borderRadius: 8,
            backgroundColor: '#E9E8ED'
          }}
          w={40}
        >
          <Icon color='#9B98AE' size={24} />
        </Center>
      </Center>

      <Center mt={6}>
        <Text c='gray.7' fw={600} size='sm'>
          {`No recent ${label.toLowerCase()} projects`}
        </Text>
      </Center>

      <Center mt={8}>
        {type === PROJECT_TYPE.TRAINING_INFERENCE ? (
          <UnstyledButton
            component='a'
            href='https://docs.rungalileo.io/galileo/llm-studio/llm-debugger/getting-started'
            target='_blank'
          >
            <Group gap={6}>
              <IconExternalLink color='#4A2FF9' size={16} />
              <Text c='#4A2FF9' fw={600} size='sm'>
                {`Create a ${shortLabel} Run`}
              </Text>
            </Group>
          </UnstyledButton>
        ) : (
          <Text c='gray.8' size='sm'>
            Create a new one{' '}
            <Text
              c='#4A2FF9'
              component='span'
              fw={600}
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                setIsProjectMenuOpen(false);
                router.push(Paths.CREATE_NEW_PROJECT);
              }}
            >
              here
            </Text>
          </Text>
        )}
      </Center>
    </Box>
  );
};

export default NoProjectsByType;
